<template>
  <Content :search="false" v-if="catalogItem">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <h2>{{catalogItem.name}}</h2>
      </div>
    </template>
    <template v-slot:topbar>
      <template v-if="this.$userCan('manage_catalog_items') && catalogItem.allow_delete">
        <div class="flex flex-wrap items-center">
          <Button icon="delete" @click="deleteCatalogItem" />
        </div>
      </template>
    </template>
    
    <template v-slot:content>
      <div>
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <CatalogItemDetails :item="catalogItem" />

            <CatalogItemSuppliers :catalogItem="catalogItem" />

            <template v-if="catalogItem.stock_items">
              <div class="w-full bg-white dark:bg-gray-750 px-4 sm:px-6 py-4 rounded-lg">
                <div class="w-full">
                  <h2 class="text-lg font-bold mb-4 dark:text-gray-300">
                    Noliktavas atlikumi
                  </h2>
                </div>
                <template v-for="(item, index) in catalogItem.stock_items" :key="index">
                  <SingleStockItemDetails :item="item" />
                </template>
              </div>
            </template>

          </ul>

          <template v-if="catalogItem.available_units">
            <div class="flex items-center mt-6 px-4 gap-2">
              <h2 class="text-lg font-bold mb-0 dark:text-gray-300 ">
                Pieejamās vienības
              </h2>
              <div>
                <div  @click="showOnlyUnitCodes = !showOnlyUnitCodes" class="cursor-pointer">
                  <span v-if="!showOnlyUnitCodes">Tikai kodi</span>
                  <span v-if="showOnlyUnitCodes">Tabulas skats</span>
                </div>
              </div>
            </div>

            <div  class=" ">
              <table v-if="!showOnlyUnitCodes" class="mt-2 ring-1 ring-gray-300 sm:mx-0 rounded-lg bg-white min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Kods</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Piegādātājs</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">PPR nr.</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Cena EUR</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-300">

                <tr v-for="(item, index) in catalogItem.available_units" :key="index">
                  <td class="relative py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="font-medium text-gray-900">{{item.number}}</div>
                    <div class="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                      <span>{{item.procurement.supplier}} / {{item.procurement.supplier_uuid}}</span>
                    </div>
                  </td>
                  <td class="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">{{item.procurement.supplier}}</td>
                  <td class="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">{{item.procurement.supplier_uuid}}</td>
                  <td class="px-3 py-3.5 text-sm text-gray-500">
                    <div class="sm:hidden">{{item.price}} €</div>
                    <div class="hidden sm:block">{{item.price}}</div>
                  </td>
                  <td class="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a :href="'/catalog_item_units/' + item.id" type="button" class="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold
                    text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white">
                      Atvērt
                    </a>
                  </td>
                </tr>

                </tbody>
              </table>

            </div>

            <div v-if="showOnlyUnitCodes" class="mt-2 ring-1 ring-gray-300 sm:mx-0 rounded-lg bg-white pb-2" >
              <div class="px-4 mt-2 pt-2 font-semibold"> Kodi </div>
              <div class="mx-4 mt-2 mb-2 bg-gray-200 rounded-lg py-2 px-2">
                <div v-for="(item, index) in catalogItem.available_units" :key="index">
                  {{item.number}}
                </div>
              </div>
            </div>

          </template>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'

import { defineAsyncComponent } from 'vue'

const CatalogItemDetails = defineAsyncComponent(() =>
  import('@/components/Settings/Catalog/CatalogItem/CatalogItemDetails'))
const CatalogItemSuppliers = defineAsyncComponent(() =>
  import('@/components/Settings/Catalog/CatalogItem/Suppliers/CatalogItemSuppliers'))
const SingleStockItemDetails = defineAsyncComponent(() =>
  import('@/components/Stock/StockItems/SingleStockItemDetails'))


export default {
  name: "CatalogItem",
  components: {
    CatalogItemDetails,
    CatalogItemSuppliers,
    SingleStockItemDetails,
  },
  data(){
    return {
      showOnlyUnitCodes: false
    }
  },
  created() {
    this.$store.dispatch('clearEditableItem')
    this.$store.dispatch('clearSingleCatalogItem')
    this.$store.dispatch('getSingleCatalogItem', this.$route.params.catalogItemId)
  },
  computed: {
    ...mapGetters({
        catalogItem: 'catalogItem',
    })
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    deleteCatalogItem() {
      if (confirm('Vai esi drošs?')) {
        this.$Progress.start()
        this.$store.dispatch('deleteCatalogItem', this.catalogItem.id)
      }
    },
  }
}
</script>

<style>

</style>